import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

import thumb01 from '../assets/images/thumbs/01.jpg'
import thumb02 from '../assets/images/thumbs/02.jpg'
import thumb03 from '../assets/images/thumbs/03.jpg'
import thumb04 from '../assets/images/thumbs/04.jpg'
import thumb05 from '../assets/images/thumbs/05.jpg'
import thumb06 from '../assets/images/thumbs/06.jpg'

import full01 from '../assets/images/fulls/01.jpg'
import full02 from '../assets/images/fulls/02.jpg'
import full03 from '../assets/images/fulls/03.jpg'
import full04 from '../assets/images/fulls/04.jpg'
import full05 from '../assets/images/fulls/05.jpg'
import full06 from '../assets/images/fulls/06.jpg'

const DEFAULT_IMAGES = [
    { id: '1', src: full01, thumbnail: thumb01, caption: 'Prpject 1', description: '.'},
    { id: '2', src: full02, thumbnail: thumb02, caption: 'Prpject 2', description: '.'},
    { id: '3', src: full03, thumbnail: thumb03, caption: 'Prpject 3', description: '.'},
    { id: '4', src: full04, thumbnail: thumb04, caption: 'Prpject 4', description: '.'},
    { id: '5', src: full05, thumbnail: thumb05, caption: 'Prpject 5', description: '.'},
    { id: '6', src: full06, thumbnail: thumb06, caption: 'Prpject 6', description: '.'}
];

class HomeIndex extends React.Component {

    constructor() {
        super();

        this.state = {
            lightboxIsOpen: false,
            currentImage: 0,
        };

        this.closeLightbox = this.closeLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.handleClickImage = this.handleClickImage.bind(this);
    }

    openLightbox (index, event) {
        event.preventDefault();
        this.setState({
            currentImage: index,
            lightboxIsOpen: true,
        });
    }
    closeLightbox () {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    gotoPrevious () {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }
    gotoNext () {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }
    handleClickImage () {
        if (this.state.currentImage === this.props.images.length - 1) return;

        this.gotoNext();
    }

    render() {
        const siteTitle = "Sara Nouri"
        const siteDescription = "CV"

        return (
            <Layout>
                <Helmet>
                        <title>{siteTitle}</title>
                        <meta name="description" content={siteDescription} />
                </Helmet>

                <div id="main">

                    <section id="one">
                        <header className="major">
                            <h2>Novice programmer.<br />Undergraduate student, University of Kashan.<br />Iran, Tehran !</h2>
                        </header>
                        <p className="text-align-desc">I am always looking for new challenges and solving various issues. I have a lot of self-confidence and great interest in teamwork. I have a high positive energy and I try to transfer the energy to others, and expect the same from others. I was always looking for big goals and doing my best to achieve these goals. Looking to build a beautiful world without limit and spirit of your invincibility, I'm attaining this goal.</p>
                        <ul className="actions">
                            <li><a href="https://github.com/saranouri" className="button"><span className="icon fa-arrow-down "></span> Download Resume</a></li>
                        </ul>
                    </section>

                {/* 

                        <section id="two">
                        <h2>Recent Project</h2>

                        <Gallery images={DEFAULT_IMAGES.map(({ id, src, thumbnail, caption, description }) => ({
                            src,
                            thumbnail,
                            caption,
                            description
                        }))} />

                        <ul className="actions">
                            <li><a href="https://alisouran.ir" className="button">Full Portfolio</a></li>
                        </ul>
                    </section>

                */}
                    <section id="three">
                        <h2>Get In Touch</h2>
                        <p>To contact us, please use the form below to request a project or partnership or offer.</p>
                        <div className="row">
                            <div className="8u 12u$(small)">
                                <form method="post" action="https://formspree.io/hi@saranouri.ir">
                                    <div className="row uniform 50%">
                                        <div className="6u 12u$(xsmall)"><input type="text" name="name" id="name" placeholder="Name" /></div>
                                        <div className="6u 12u$(xsmall)"><input type="email" name="email" id="email" placeholder="Email" /></div>
                                        <div className="12u"><textarea name="message" id="message" placeholder="Message" rows="4"></textarea></div>
                                    </div>
                                    <ul className="actions padding-top-15">
                                        <li><input type="submit" value="Send Message" /></li>
                                    </ul>
                                </form>
                               
                            </div>
                            <div className="4u 12u$(small)">
                                <ul className="labeled-icons">
                                    <li>
                                        <h3 className="icon fa-home"><span className="label">Address</span></h3>
                                        Isfahan<br />
                                        IRIran
                                    </li>
                                    <li>
                                        <h3 className="icon fa-mobile"><span className="label">Phone</span></h3>
                                        +98(938)-817-9097
                                    </li>
                                    <li>
                                        <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                        <a href="mailto:hi@saranouri.ir">hi@saranouri.ir</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                </div>

            </Layout>
        )
    }
}

export default HomeIndex